@import "../variables";

@font-face {
    font-family: '#{$icon-font-family}';
    src: url('../#{$icon-font-path}/#{$icon-font-family}.ttf?n8x72w') format('truetype'),
    url('../#{$icon-font-path}/#{$icon-font-family}.woff?n8x72w') format('woff'),
    url('../#{$icon-font-path}/#{$icon-font-family}.svg?n8x72w##{$icon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}